<template>
    <div>
        <b-container>
            <section class="profile">
                <b-row>
                <b-col cols="12" md="4" lg="3">
                    <ProfileNavigation />
                </b-col>
                    <b-col cols="12" md="8" lg="9">
                        <div class="sector pl-0 pr-0">
                            <h2 class="profile__title mb-4">Edit agency</h2>
                            <div v-if="!agencyLoaded" class="profile__content mt-1">
                                <Spinner size="medium" line-fg-color="#e91e63" />
                            </div>
                            <div v-else class="profile__content">
                                <p v-if="updateAgencyForm.localError" class="custom-error mb-3">{{ updateAgencyForm.localError }}</p>
                                <p v-if="getAgencyError" class="custom-error mb-3"> {{ getAgencyError }} </p>
                                <form :class="getDisabledAgency ? 'disabled-element' : ''" @submit.prevent="updateAgency" autocomplete="off">
                                    <b-row>
                                        <b-col cols="12">
                                            <span class="profile__label float-right">*Required fields</span>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Company name*</label>
                                                <input :disabled="getDisabledAgency" v-model.trim="updateAgencyForm.agencyName" @input="$v.updateAgencyForm.agencyName.$touch()" :class="[{'input-error' : $v.updateAgencyForm.agencyName.$error }, getDisabledAgency ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type company name" autocomplete="off" />
                                                <div v-if="$v.updateAgencyForm.agencyName.$error">
                                                    <p v-if="!$v.updateAgencyForm.agencyName.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateAgencyForm.agencyName.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Employees number*</label>
                                                <v-select :disabled="getDisabledAgency" @input="$v.updateAgencyForm.agencyEmployees.$touch()" :class="[{'input-error' : $v.updateAgencyForm.agencyEmployees.$error }, getDisabledAgency ? 'disabled-element' : '' ]" v-model.trim="updateAgencyForm.agencyEmployees" class="custom-sorter mb-1" labelTitle = "Select employees number" :options="employees" />
                                                <div v-if="$v.updateAgencyForm.agencyEmployees.$error">
                                                    <p v-if="!$v.updateAgencyForm.agencyEmployees.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateAgencyForm.agencyEmployees.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">E-mail address</label>
                                                <input :disabled="getDisabledAgency" v-model.trim="updateAgencyForm.agencyEmail" @input="$v.updateAgencyForm.agencyEmail.$touch()" :class="[{'input-error' : $v.updateAgencyForm.agencyEmail.$error }, getDisabledAgency ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type company e-mail" autocomplete="off" />
                                                <div v-if="$v.updateAgencyForm.agencyEmail.$error">
                                                    <p v-if="!$v.updateAgencyForm.agencyEmail.email" class="custom-error">E-mail address is invalid</p>
                                                    <p v-else-if="!$v.updateAgencyForm.agencyEmail.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Company website URL<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />With http or https protocol</span></label>
                                                <input :disabled="getDisabledAgency" v-model.trim="updateAgencyForm.agencyUrl" @input="$v.updateAgencyForm.agencyUrl.$touch()" :class="[{'input-error' : $v.updateAgencyForm.agencyUrl.$error }, getDisabledAgency ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type company website URL" autocomplete="off" />
                                                <div v-if="$v.updateAgencyForm.agencyUrl.$error">
                                                    <p v-if="!$v.updateAgencyForm.agencyUrl.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateAgencyForm.agencyUrl.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                    <p v-else-if="!$v.updateAgencyForm.agencyUrl.urlCustomValidator" class="custom-error">This field must start with either http:// or https://</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">City*</label>
                                                <input :disabled="getDisabledAgency" v-model.trim="updateAgencyForm.agencyCity" @input="$v.updateAgencyForm.agencyCity.$touch()" :class="[{'input-error' : $v.updateAgencyForm.agencyCity.$error }, getDisabledAgency ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type city" autocomplete="off" />
                                                <div v-if="$v.updateAgencyForm.agencyCity.$error">
                                                    <p v-if="!$v.updateAgencyForm.agencyCity.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateAgencyForm.agencyCity.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Minimum project size*</label>
                                                <v-select :disabled="getDisabledAgency" @input="$v.updateAgencyForm.agencyProject.$touch()" :class="[{'input-error' : $v.updateAgencyForm.agencyProject.$error }, getDisabledAgency ? 'disabled-element' : '' ]" v-model.trim="updateAgencyForm.agencyProject" class="custom-sorter mb-1" labelTitle = "Select min project size" :options="projectSizes" />
                                                <div v-if="$v.updateAgencyForm.agencyProject.$error">
                                                    <p v-if="!$v.updateAgencyForm.agencyProject.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateAgencyForm.agencyProject.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Founding year*</label>
                                                <date-picker  :disabled="getDisabledAgency" :class="[{'input-error' : $v.updateAgencyForm.agencyFounded.$error }, getDisabledAgency ? 'disabled-element' : '' ]" v-model.trim="updateAgencyForm.agencyFounded" class="mb-1"  lang="en" type="year" format="YYYY" placeholder="Select founding year"></date-picker>
                                                <div v-if="$v.updateAgencyForm.agencyFounded.$error">
                                                    <p v-if="!$v.updateAgencyForm.agencyFounded.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateAgencyForm.agencyFounded.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Phone number</label>
                                                <input :disabled="getDisabledAgency" v-model.trim="updateAgencyForm.agencyPhone" @input="$v.updateAgencyForm.agencyPhone.$touch()" :class="[{'input-error' : $v.updateAgencyForm.agencyPhone.$error }, getDisabledAgency ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type company phone number" autocomplete="off" />
                                                <div v-if="$v.updateAgencyForm.agencyPhone.$error">
                                                    <p v-if="!$v.updateAgencyForm.agencyPhone.maxLength" class="custom-error">This field must not exceed 20 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Company address*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Street name and street number</span></label>
                                                <input :disabled="getDisabledAgency" v-model.trim="updateAgencyForm.agencyAddress" @input="$v.updateAgencyForm.agencyAddress.$touch()" :class="[{'input-error' : $v.updateAgencyForm.agencyAddress.$error }, getDisabledAgency ? 'disabled-element' : '' ]" class="form-input mb-1" placeholder="Type company address" autocomplete="off" />
                                                <div v-if="$v.updateAgencyForm.agencyAddress.$error">
                                                    <p v-if="!$v.updateAgencyForm.agencyAddress.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateAgencyForm.agencyAddress.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Country*</label>
                                                <v-select :disabled="getDisabledAgency" @input="$v.updateAgencyForm.agencyCountry.$touch()" :class="[{'input-error' : $v.updateAgencyForm.agencyCountry.$error }, getDisabledAgency ? 'disabled-element' : '' ]" v-model.trim="updateAgencyForm.agencyCountry" class="custom-sorter mb-1" labelTitle = "Select country" :options="countries" :searchable = true />
                                                <div v-if="$v.updateAgencyForm.agencyCountry.$error">
                                                    <p v-if="!$v.updateAgencyForm.agencyCountry.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateAgencyForm.agencyCountry.maxLength" class="custom-error">This field must not exceed 200 characters</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Company description*</label>
                                                <vue-editor :disabled="getDisabledAgency" v-model.trim="updateAgencyForm.agencyDescription" @input="$v.updateAgencyForm.agencyDescription.$touch()" :class="[{'input-error' : $v.updateAgencyForm.agencyDescription.$error }, getDisabledAgency ? 'disabled-element' : '' ]" :editorToolbar="customToolbar"></vue-editor>
                                                <div v-if="$v.updateAgencyForm.agencyDescription.$error">
                                                    <p v-if="!$v.updateAgencyForm.agencyDescription.required" class="custom-error">This field is required</p>
                                                    <p v-else-if="!$v.updateAgencyForm.agencyDescription.maxLength" class="custom-error">This field must not exceed 3000 characters</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Company services*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Tick services that your company provides</span></label><br>
                                                <p-check v-model="updateAgencyForm.advertising" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Advertising
                                                </p-check>
                                                <p-check v-model="updateAgencyForm.digital" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Digital marketing
                                                </p-check>
                                                <p-check v-model="updateAgencyForm.branding" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Branding
                                                </p-check>
                                                <p-check v-model="updateAgencyForm.social" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Social media marketing
                                                </p-check>
                                                <p-check v-model="updateAgencyForm.webDev" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Web development
                                                </p-check>
                                                <p-check v-model="updateAgencyForm.mobile" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Mobile app development
                                                </p-check>
                                                <p-check v-model="updateAgencyForm.commerce" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>eCommerce
                                                </p-check>
                                                <p-check v-model="updateAgencyForm.data" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Big Data
                                                </p-check>
                                                <p-check v-model="updateAgencyForm.emailMarketing" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>E-mail marketing
                                                </p-check>
                                                <p-check v-model="updateAgencyForm.eventMarketing" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Event marketing
                                                </p-check>
                                                <p-check v-model="updateAgencyForm.cloud" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Cloud solutions
                                                </p-check>
                                                <p-check v-model="updateAgencyForm.seo" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>SEO
                                                </p-check>
                                                <p-check v-model="updateAgencyForm.graphic" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Graphic design
                                                </p-check>
                                                <p-check v-model="updateAgencyForm.print" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Print design
                                                </p-check>
                                                <p-check v-model="updateAgencyForm.video" class="p-svg p-curve p-smooth" color="success">
                                                    <!-- svg path -->
                                                    <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                                        <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                                            style="stroke: white;fill:white"></path>
                                                    </svg>Video production
                                                </p-check>
                                                <div>
                                                    <p v-if="updateAgencyForm.servicesError" class="custom-error mb-3">{{ updateAgencyForm.servicesError }}</p>
                                                </div>
                                            </div>
                                            <div class="profile__element">
                                                <label class="profile__label">Location* <span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Move the blue marker on the map and specify the location</span></label>
                                                <div class="profile__map">
                                                    <l-map :zoom="zoom" :center="center" :options="{zoomControl: false}">
                                                        <l-tile-layer
                                                        :url="url"
                                                        layer-type="base"></l-tile-layer>
                                                        <l-marker :lat-lng="updateAgencyForm.agencyMarker" :draggable="true" @update:latLng="positionUpdate"></l-marker>
                                                        <l-control-zoom position="topright"></l-control-zoom>
                                                    </l-map>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Latitude</label>
                                                <input readonly :disabled="getDisabledAgency" @input="$v.updateAgencyForm.agencyMarker.lat.$touch()" :class="[{'input-error' : $v.updateAgencyForm.agencyMarker.lat.$error }, getDisabledAgency ? 'disabled-element' : '' ]" v-model="updateAgencyForm.agencyMarker.lat" class="form-input mb-1" />
                                                <div v-if="$v.updateAgencyForm.agencyMarker.lat.$error">
                                                    <p v-if="!$v.updateAgencyForm.agencyMarker.lat.required" class="custom-error">This field is required</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12" lg="6">
                                            <div class="profile__element">
                                                <label class="profile__label">Longitude</label>
                                                <input readonly :disabled="getDisabledAgency" @input="$v.updateAgencyForm.agencyMarker.lat.$touch()" :class="[{'input-error' : $v.updateAgencyForm.agencyMarker.lat.$error }, getDisabledAgency ? 'disabled-element' : '' ]" v-model="updateAgencyForm.agencyMarker.lat" class="form-input mb-1" />
                                                <div v-if="$v.updateAgencyForm.agencyMarker.lat.$error">
                                                    <p v-if="!$v.updateAgencyForm.agencyMarker.lat.required" class="custom-error">This field is required</p>
                                                </div>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <div class="profile__element">
                                                <label class="profile__label">Company logo*<span class="tip"><simple-line-icons icon="info" size="small" color="#504e70" />Recommended size: 150px by 150px with transparent background, JPG, PNG, max 5MB</span></label>
                                                <b-row>
                                                    <b-col cols="12">
                                                        <div :style="{ backgroundImage: 'url(' + updateAgencyForm.currentImage + ')' }" id="agencyLogo" class="profile__logo-image"></div>
                                                    </b-col>
                                                    <b-col cols="12">
                                                        <div class="custom-upload mt-3">
                                                            <label for="fileUpload" class="custom-upload-label">
                                                                <simple-line-icons icon="picture" size="small" color="#504e70" />Choose file
                                                            </label>
                                                            <input :class="getDisabledAgency ? 'disabled-element' : ''" @change="onFileChanged" id="fileUpload" type="file"/>
                                                        </div>
                                                    </b-col>
                                                </b-row>
                                            </div>
                                        </b-col>
                                        <b-col cols="12">
                                            <button :disabled="getDisabledAgency" type="submit" class="details-button details-button--red"><simple-line-icons icon="arrowRight" size="small" color="#504e70" />Update agency</button>
                                        </b-col>
                                    </b-row>
                                </form>
                            </div>
                        </div>
                    </b-col>
                </b-row>
            </section>
        </b-container>
    </div>
</template>

<script>
import ProfileNavigation from '@/components/ProfileNavigation.vue'
import SimpleLineIcons from 'vue-simple-line'
import VSelect from '../assets/files/customSelect.esm.js'
import PrettyCheck from 'pretty-checkbox-vue/check'
import DatePicker from 'vue2-datepicker'
import { VueEditor, Quill } from 'vue2-editor'
import countriesList from '../assets/files/countriesList.js'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
import moment from 'moment'
import firebase from 'firebase/app'
import router from '../router/router'
import {
  email,
  required,
  maxLength
} from 'vuelidate/lib/validators'
import {
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LIcon,
  LControlZoom
} from 'vue2-leaflet'

function urlCustomValidator (value) {
  var pattern = /^((http|https):\/\/)/

  if (!pattern.test(value)) {
    return false
  } else {
    return true
  }
}

export default {
  components: {
    ProfileNavigation,
    SimpleLineIcons,
    VSelect,
    'p-check': PrettyCheck,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
    LControlZoom,
    DatePicker,
    VueEditor,
    Spinner
  },
  data: function () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      zoom: 16,
      center: null,
      seo: false,
      marketing: false,
      customToolbar: [
        ['bold', 'italic', 'underline', 'link'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['clean']
      ],
      projectSizes: [
        { value: '0', text: 'None' },
        { value: '1', text: '<$1000' },
        { value: '2', text: '$5000+' },
        { value: '3', text: '$10 000+' },
        { value: '4', text: '$25 000+' },
        { value: '5', text: '$50 000+' },
        { value: '6', text: '$75 000+' },
        { value: '7', text: '$100 000+' },
        { value: '8', text: '$200 000+' }
      ],
      employees: [
        { value: '0', text: '1-10' },
        { value: '1', text: '11-25' },
        { value: '2', text: '26-50' },
        { value: '3', text: '51-75' },
        { value: '4', text: '76-100' },
        { value: '5', text: '101-150' },
        { value: '6', text: '151-200' },
        { value: '7', text: '201-500' },
        { value: '8', text: '501+' }
      ],
      countries: [],
      agencyLoaded: false,
      updateAgencyForm: {
        agencyName: null,
        agencyEmployees: null,
        localError: null,
        agencyEmail: '',
        agencyPhone: '',
        agencyUrl: '',
        agencyAddress: null,
        agencyCountry: null,
        agencyCity: null,
        agencyProject: null,
        agencyFounded: null,
        agencyDescription: null,
        advertising: false,
        digital: false,
        branding: false,
        social: false,
        webDev: false,
        mobile: false,
        commerce: false,
        data: false,
        emailMarketing: false,
        eventMarketing: false,
        cloud: false,
        seo: false,
        graphic: false,
        print: false,
        video: false,
        agencyMarker: {
          lat: 40.73461490255838, lng: -73.98722073063256
        },
        selectedFile: null,
        servicesError: null,
        agencyId: null,
        currentImage: null,
        imageName: null
      }
    }
  },
  validations: {
    updateAgencyForm: {
      agencyName: {
        required: required,
        maxLength: maxLength(200)
      },
      agencyEmployees: {
        required: required,
        maxLength: maxLength(200)
      },
      agencyEmail: {
        email: email,
        maxLength: maxLength(200)
      },
      agencyUrl: {
        required: required,
        maxLength: maxLength(200),
        urlCustomValidator
      },
      agencyPhone: {
        maxLength: maxLength(20)
      },
      agencyAddress: {
        required: required,
        maxLength: maxLength(200)
      },
      agencyCountry: {
        required: required,
        maxLength: maxLength(200)
      },
      agencyCity: {
        required: required,
        maxLength: maxLength(200)
      },
      agencyProject: {
        required: required,
        maxLength: maxLength(200)
      },
      agencyFounded: {
        required: required,
        maxLength: maxLength(200)
      },
      agencyDescription: {
        required: required,
        maxLength: maxLength(3000)
      },
      agencyMarker: {
        lat: {
          required: required
        },
        lng: {
          required: required
        }
      }
    }
  },
  methods: {
    positionUpdate (latLng) {
      this.updateAgencyForm.agencyMarker = latLng
    },
    updateAgency () {
      this.$store.dispatch('setDisabledAgency')
      this.$v.updateAgencyForm.$touch()
      if (this.updateAgencyForm.advertising == false && this.updateAgencyForm.digital == false && this.updateAgencyForm.branding == false && this.updateAgencyForm.social == false && this.updateAgencyForm.webDev == false && this.updateAgencyForm.mobile == false && this.updateAgencyForm.commerce == false && this.updateAgencyForm.data == false && this.updateAgencyForm.emailMarketing == false && this.updateAgencyForm.eventMarketing == false && this.updateAgencyForm.cloud == false && this.updateAgencyForm.seo == false && this.updateAgencyForm.graphic == false && this.updateAgencyForm.print == false && this.updateAgencyForm.video == false) {
        this.updateAgencyForm.servicesError = 'At least one service has to be checked'
        this.scrollToTop(500)
        this.updateAgencyForm.localError = 'Invalid form'
        this.$store.dispatch('clearDisabledAgency')
      } else {
        this.updateAgencyForm.servicesError = null
        if (this.updateAgencyForm.selectedFile !== null) {
          if (!this.$v.updateAgencyForm.$invalid) {
            this.$store.dispatch('updateAgencyWithImage', { agencyId: this.updateAgencyForm.agencyId, name: this.updateAgencyForm.agencyName, employees: this.updateAgencyForm.agencyEmployees, email: this.updateAgencyForm.agencyEmail, website: this.updateAgencyForm.agencyUrl, phone: this.updateAgencyForm.agencyPhone, address: this.updateAgencyForm.agencyAddress, country: this.updateAgencyForm.agencyCountry, city: this.updateAgencyForm.agencyCity, project: this.updateAgencyForm.agencyProject, founded: this.updateAgencyForm.agencyFounded, desc: this.updateAgencyForm.agencyDescription, advertising: this.updateAgencyForm.advertising, digital: this.updateAgencyForm.digital, branding: this.updateAgencyForm.branding, social: this.updateAgencyForm.social, webDev: this.updateAgencyForm.webDev, mobile: this.updateAgencyForm.mobile, commerce: this.updateAgencyForm.commerce, data: this.updateAgencyForm.data, emailMarketing: this.updateAgencyForm.emailMarketing, eventMarketing: this.updateAgencyForm.eventMarketing, cloud: this.updateAgencyForm.cloud, seo: this.updateAgencyForm.seo, graphic: this.updateAgencyForm.graphic, print: this.updateAgencyForm.print, video: this.updateAgencyForm.video, lat: this.updateAgencyForm.agencyMarker.lat, lng: this.updateAgencyForm.agencyMarker.lng, image: this.updateAgencyForm.selectedFile, imageName: this.updateAgencyForm.imageName })
              .then(() => {
                this.scrollToTop(500)
              })
              .catch(error => {
                this.scrollToTop(500)
                this.updateAgencyForm.localError = error.message
                this.$store.dispatch('clearDisabledAgency')
              })
          } else {
            this.scrollToTop(500)
            this.updateAgencyForm.localError = 'Invalid form'
            this.$store.dispatch('clearDisabledAgency')
          }
        } else {
          if (!this.$v.updateAgencyForm.$invalid) {
            this.$store.dispatch('updateAgencyWithoutImage', { agencyId: this.updateAgencyForm.agencyId, name: this.updateAgencyForm.agencyName, employees: this.updateAgencyForm.agencyEmployees, email: this.updateAgencyForm.agencyEmail, website: this.updateAgencyForm.agencyUrl, phone: this.updateAgencyForm.agencyPhone, address: this.updateAgencyForm.agencyAddress, country: this.updateAgencyForm.agencyCountry, city: this.updateAgencyForm.agencyCity, project: this.updateAgencyForm.agencyProject, founded: this.updateAgencyForm.agencyFounded, desc: this.updateAgencyForm.agencyDescription, advertising: this.updateAgencyForm.advertising, digital: this.updateAgencyForm.digital, branding: this.updateAgencyForm.branding, social: this.updateAgencyForm.social, webDev: this.updateAgencyForm.webDev, mobile: this.updateAgencyForm.mobile, commerce: this.updateAgencyForm.commerce, data: this.updateAgencyForm.data, emailMarketing: this.updateAgencyForm.emailMarketing, eventMarketing: this.updateAgencyForm.eventMarketing, cloud: this.updateAgencyForm.cloud, seo: this.updateAgencyForm.seo, graphic: this.updateAgencyForm.graphic, print: this.updateAgencyForm.print, video: this.updateAgencyForm.video, lat: this.updateAgencyForm.agencyMarker.lat, lng: this.updateAgencyForm.agencyMarker.lng })
              .then(() => {
                this.scrollToTop(500)
              })
              .catch(error => {
                this.scrollToTop(500)
                this.updateAgencyForm.localError = error.message
                this.$store.dispatch('clearDisabledAgency')
              })
          } else {
            this.scrollToTop(500)
            this.updateAgencyForm.localError = 'Invalid form'
            this.$store.dispatch('clearDisabledAgency')
          }
        }
      }
    },
    scrollToTop (scrollDuration) {
      var scrollStep = -window.scrollY / (scrollDuration / 15)
      var scrollInterval = setInterval(function () {
        if (window.scrollY != 0) {
          window.scrollBy(0, scrollStep)
        } else clearInterval(scrollInterval)
      }, 15)
    },
    onFileChanged (event) {
      if (event.target.files[0] != undefined) {
        this.updateAgencyForm.selectedFile = event.target.files[0]

        var inputFile = document.querySelector('#fileUpload')

        var selectedValue = document.querySelector('#fileUpload').value

        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i

        var fileSize = this.updateAgencyForm.selectedFile.size / 1024 / 1024 // in MB

        if (!allowedExtensions.exec(selectedValue)) {
          this.$swal({
            type: 'error',
            title: 'Invalid file extension',
            text: 'Supported file formats: .jpeg .jpg .png .gif',
            confirmButtonText: 'OK'
          })

          inputFile.value = ''

          this.updateAgencyForm.selectedFile = null

          return false
        } else if (fileSize > 5) {
          this.$swal({
            type: 'error',
            title: 'File size is too large',
            text: 'Uploaded file size must not exceed 5MB',
            confirmButtonText: 'OK'
          })

          inputFile.value = ''

          this.updateAgencyForm.selectedFile = null

          return false
        } else {
          // Image preview
          if (this.updateAgencyForm.selectedFile) {
            var reader = new FileReader()
            reader.onload = function (e) {
              document.getElementById('agencyLogo').style.backgroundImage = 'url("' + e.target.result + '")'
            }
            reader.readAsDataURL(inputFile.files[0])
          }
        }
      }
    }
  },
  beforeCreate () {
    this.$store.dispatch('clearError')
    this.$store.dispatch('clearDisabledAgency')
    this.$store.dispatch('getUserAgencies')
  },
  created () {
    this.countries = countriesList

    var user = firebase.auth().currentUser

    var agenciesRef = firebase.database().ref('agencies')

    agenciesRef.child(this.$route.params.id).on('value', snapshot => {
      (this.updateAgencyForm.agencyId = snapshot.key),
      (this.updateAgencyForm.agencyName = snapshot.val().name),
      (this.updateAgencyForm.agencyEmployees = snapshot.val().employees),
      (this.updateAgencyForm.agencyEmail = snapshot.val().email),
      (this.updateAgencyForm.agencyUrl = snapshot.val().website),
      (this.updateAgencyForm.agencyPhone = snapshot.val().phone),
      (this.updateAgencyForm.agencyAddress = snapshot.val().address),
      (this.updateAgencyForm.agencyCountry = snapshot.val().country),
      (this.updateAgencyForm.agencyCity = snapshot.val().city),
      (this.updateAgencyForm.agencyProject = snapshot.val().project),
      (this.updateAgencyForm.agencyFounded = new Date(moment(snapshot.val().founded, 'YYYY'))),
      (this.updateAgencyForm.agencyDescription = snapshot.val().desc),
      (this.updateAgencyForm.advertising = snapshot.val().services.advertising),
      (this.updateAgencyForm.digital = snapshot.val().services.digital),
      (this.updateAgencyForm.branding = snapshot.val().services.branding),
      (this.updateAgencyForm.social = snapshot.val().services.social),
      (this.updateAgencyForm.webDev = snapshot.val().services.webDev),
      (this.updateAgencyForm.mobile = snapshot.val().services.mobile),
      (this.updateAgencyForm.commerce = snapshot.val().services.commerce),
      (this.updateAgencyForm.data = snapshot.val().services.data),
      (this.updateAgencyForm.emailMarketing = snapshot.val().services.emailMarketing),
      (this.updateAgencyForm.eventMarketing = snapshot.val().services.eventMarketing),
      (this.updateAgencyForm.cloud = snapshot.val().services.cloud),
      (this.updateAgencyForm.seo = snapshot.val().services.seo),
      (this.updateAgencyForm.graphic = snapshot.val().services.graphic),
      (this.updateAgencyForm.print = snapshot.val().services.print),
      (this.updateAgencyForm.video = snapshot.val().services.video),
      (this.updateAgencyForm.agencyMarker.lat = snapshot.val().lat),
      (this.updateAgencyForm.agencyMarker.lng = snapshot.val().lng),
      (this.center = [snapshot.val().lat, snapshot.val().lng]),
      (this.updateAgencyForm.currentImage = snapshot.val().url + '/?v' + Math.floor((Math.random() * 100) + 1)),
      (this.updateAgencyForm.imageName = snapshot.val().image)

      if (snapshot.val().user != user.uid) {
        router.push('/401')
      }

      this.agencyLoaded = true
    })
  },
  computed: {
    ...mapGetters([
      'getAgencyError',
      'getDisabledAgency'
    ])
  },
  watch: {
    'updateAgencyForm.advertising': function () {
      if (this.updateAgencyForm.advertising == true) {
        this.updateAgencyForm.servicesError = null
      }
    },
    'updateAgencyForm.digital': function () {
      if (this.updateAgencyForm.digital == true) {
        this.updateAgencyForm.servicesError = null
      }
    },
    'updateAgencyForm.branding': function () {
      if (this.updateAgencyForm.branding == true) {
        this.updateAgencyForm.servicesError = null
      }
    },
    'updateAgencyForm.social': function () {
      if (this.updateAgencyForm.social == true) {
        this.updateAgencyForm.servicesError = null
      }
    },
    'updateAgencyForm.webDev': function () {
      if (this.updateAgencyForm.webDev == true) {
        this.updateAgencyForm.servicesError = null
      }
    },
    'updateAgencyForm.mobile': function () {
      if (this.updateAgencyForm.mobile == true) {
        this.updateAgencyForm.servicesError = null
      }
    },
    'updateAgencyForm.commerce': function () {
      if (this.updateAgencyForm.commerce == true) {
        this.updateAgencyForm.servicesError = null
      }
    },
    'updateAgencyForm.data': function () {
      if (this.updateAgencyForm.data == true) {
        this.updateAgencyForm.servicesError = null
      }
    },
    'updateAgencyForm.emailMarketing': function () {
      if (this.updateAgencyForm.emailMarketing == true) {
        this.updateAgencyForm.servicesError = null
      }
    },
    'updateAgencyForm.eventMarketing': function () {
      if (this.updateAgencyForm.eventMarketing == true) {
        this.updateAgencyForm.servicesError = null
      }
    },
    'updateAgencyForm.cloud': function () {
      if (this.updateAgencyForm.cloud == true) {
        this.updateAgencyForm.servicesError = null
      }
    },
    'updateAgencyForm.seo': function () {
      if (this.updateAgencyForm.seo == true) {
        this.updateAgencyForm.servicesError = null
      }
    },
    'updateAgencyForm.graphic': function () {
      if (this.updateAgencyForm.graphic == true) {
        this.updateAgencyForm.servicesError = null
      }
    },
    'updateAgencyForm.print': function () {
      if (this.updateAgencyForm.print == true) {
        this.updateAgencyForm.servicesError = null
      }
    },
    'updateAgencyForm.video': function () {
      if (this.updateAgencyForm.video == true) {
        this.updateAgencyForm.servicesError = null
      }
    }
  }
}
</script>
